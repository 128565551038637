// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-all-pokemon-jsx": () => import("./../../src/templates/all-pokemon.jsx" /* webpackChunkName: "component---src-templates-all-pokemon-jsx" */),
  "component---src-templates-pokemon-jsx": () => import("./../../src/templates/pokemon.jsx" /* webpackChunkName: "component---src-templates-pokemon-jsx" */)
}

